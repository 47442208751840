import bg_3 from '../../assets/img/bg_3.png'
import { device } from '../../theme'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const RecoveryPassWrapper = styled(Box)`
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   cursor: default;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   gap: 16px;
   width: 100%;
   padding: 24px 122px 40px 122px;
   background-image: url(${bg_3});
   background-size: contain;
   background-repeat: no-repeat;
   @media (max-width: 900px) {
      background-size: cover;
   }

   @media ${device.md.max} {
      padding: 20px 40px;
      align-items: center;
      p {
         text-align: center;
      }
   }
   @media (max-width: 600px) {
      padding: 20px;
   }
`

export const RecoveryPassHeader = styled(Box)`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
`
