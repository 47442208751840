import AnalyticsService from '../../../services/analyticsService'
import greaterLogo from '../../../assets/img/logo/greater.svg'
import { InteractionEvents } from '../../../models/analitycs'
import closerLogo from '../../../assets/img/logo/closer.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../models/router/IRoute'
import beLogo from '../../../assets/img/logo/be.svg'
import React, { useEffect, useState } from 'react'
import { HeaderLogoContainer } from './styles'

interface Props {
   pageName: string
   isMob?: boolean
}

const HeaderLogo: React.FC<Props> = ({ pageName, isMob = false }) => {
   const [hasRendered, setHasRendered] = useState(false)
   const [isScrolled, setIsScrolled] = useState(false)
   const navigate = useNavigate()
   const location = useLocation()

   const handleClick = () => {
      AnalyticsService.track(InteractionEvents.HEADER_LOGO_CLICKED, {
         path_name: `${location.pathname}`,
         page_name: pageName,
      })
      navigate(ROUTES.HOME)
   }

   useEffect(() => {
      const container = document.getElementById('dashboard_container')

      if (!container) {
         return console.error('container id not found.')
      }

      const handleScroll = () => {
         if (container.scrollTop >= window.innerHeight) {
            setIsScrolled(true)
            setHasRendered(true)
         } else {
            setIsScrolled(false)
         }
      }

      container.addEventListener('scroll', handleScroll)

      return () => {
         container.removeEventListener('scroll', handleScroll)
      }
   }, [])
   return (
      <HeaderLogoContainer
         onClick={handleClick}
         isScrolled={isScrolled}
         hasRendered={hasRendered}
      >
         <img src={beLogo} alt="be-logo" />
         <img src={greaterLogo} alt="greater-logo" />
         <img src={closerLogo} alt="closer-logo" />
      </HeaderLogoContainer>
   )
}

export default HeaderLogo
