import { SignUpBtn } from '../../components/common/Buttons'
import { Box, Typography } from '@mui/material'
import bg_3 from '../../assets/img/bg_3.png'
import { device } from '../../theme'
import { styled } from '@mui/system'

export const RegistrationFormWrapper = styled(Box)`
   user-select: none;
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   cursor: default;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   gap: 16px;
   width: 100%;
   padding: 32px 122px 40px 122px;
   background-image: url(${bg_3});
   background-size: contain;
   background-repeat: no-repeat;
   @media (max-width: 900px) {
      background-size: cover;
   }

   @media ${device.md.max} {
      padding: 20px 40px;
      align-items: center;
   }
   @media (max-width: 600px) {
      padding: 20px;
   }
`
export const RegistrationFormHeader = styled(Box)`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
`

export const RegistrationFormContainer = styled(Box)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 378px;
   margin: 0 50px;
   height: 100%;
   box-sizing: border-box;

   form {
      margin: 48px 0 0 0;
      width: inherit;
      display: flex;
      flex-direction: column;
   }

   @media (max-width: 600px) {
      width: 100%;
   }
`

export const SubmitBtn = styled(SignUpBtn)`
   width: 100%;
`
export const PrivacyPolicyContainer = styled(Typography)`
   text-align: center;
   margin: 24px 0;
`

export const HaveAccountContainer = styled(PrivacyPolicyContainer)`
   font-weight: 700;
   span {
      margin: 0 4px;
      cursor: pointer;
      color: ${({ theme }) => theme.palette.white.primary};
   }
`
