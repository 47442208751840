import { ScrollContainer } from '../../components/common/styles'
import { styled } from '@mui/system'
import { device } from '../../theme'
import { Box } from '@mui/material'

export const TermsHeaderContainer = styled(Box)`
   width: 100%;
   box-sizing: border-box;
   padding: 36px 0 24px 0;
   user-select: none;
   img {
      cursor: pointer;
   }
`

export const TermsContainer = styled(Box)`
   user-select: none;
   display: flex;
   width: 100%;
   height: 100vh;
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   flex-direction: column;
   align-items: center;
   position: relative;
   padding: 0 122px;

   @media ${device.md.max} {
      padding: 0 40px;
   }
   @media (max-width: 600px) {
      padding: 0 20px;
   }
`

export const TermsScrollContainer = styled(ScrollContainer)`
   box-sizing: border-box;
   padding: 0 0 48px 0;
   h1,
   h2,
   h3,
   p,
   li,
   ul {
      font-family: 'Manrope, sans-serif';
      color: ${({ theme }) => theme.palette.white.secondary};
   }
   a {
      color: ${({ theme }) => theme.palette.white.primary};
   }
`
